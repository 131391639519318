@import "~@bfl/components/theming/variables";
@import "~@bfl/components/theming/utils";

.blue-icon {
  color: $primary-default;
  font-size: 26px;
  margin-right: $bfc-default-spacing;
}

// Formatierungen für das Legende Overlay
#highstock-container svg {
  @include bfc-font-type($regular-font);

  .highcharts-legend-item > text {
    font-weight: normal;
    @include bfc-font-size($control-font-size);
  }

  .graph-tooltip {
    @include bfc-font-type($regular-font);
    width: 160px;
    height: 320px;
    white-space: normal;
    padding-left: 0.5 * $bfc-default-spacing;

    .headline {
      @include bfc-font-size($info-font-size);
      padding-bottom: $bfc-default-spacing;
    }

    .serie {
      @include bfc-font-size($info-font-size);
    }

    .value-unit {
      @include bfc-font-size($info-font-size);
      padding-bottom: $bfc-default-spacing;
    }
  }
}